@import '@fontsource/open-sans/300';
@import '@fontsource/open-sans/400';
@import '@fontsource/open-sans/600';
@import '@fontsource/open-sans/700';
@import '@fontsource/open-sans/800';
@import '@fontsource/handlee';

#root {
  zoom: 0.9;
}

code {
  font-family: source-code-pro, 'Open Sans', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a.cc-unoo[data-cshid][role='button'] {
  transform: translateY(500px);
}

img,
video {
  flex-shrink: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: none;
}

.c-pointer {
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.code-example-block {
  position: relative;
}

.copy-code-text {
  cursor: pointer;
  color: #101634;
  background-color: #fff;
  position: absolute;
  padding: 10px 16px;
  border-radius: 8px;
  display: inline-block;
  right: 20px;
  top: 20px;
}

.MuiSelect-root.Mui-focused:after {
  border: 0 !important;
  transform: scaleX(0) !important;
}

.MuiPickersCalendar-week span {
  font-size: 16px;
}

.desktop-date-range-picker .MuiPaper-root {
  box-shadow: none !important;
}
.desktop-date-range-picker .MuiPaper-root > div > div {
  display: block !important;
}
.desktop-date-range-picker .PrivatePickersSlideTransition-root {
  min-height: 236px !important;
  background: var(--pageBackgroundColor);
}

.reactEasyCrop_CropArea::before,
.reactEasyCrop_CropArea::after {
  border-color: #dfdff2 !important;
}

.reactEasyCrop_Image {
  width: auto;
  height: auto;
  flex-shrink: initial;
  object-fit: initial;
  object-position: initial;
}

.crisp-client .cc-1oun {
  z-index: 10000 !important;
  display: none;
}
.crisp-client #crisp-chatbox {
  position: relative;
  z-index: 1000 !important;
}
.crisp-client {
  position: absolute;
  z-index: 100010 !important;
}
.cc-1apq {
  transform: translateX(-65px);
}

.swiper-slide {
  width: auto;
}

body {
  position: initial !important;
}
.react-toast-notifications__container {
  z-index: 9999 !important;
}

@keyframes hideQsMarker {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes loadingDots {
  to {
    clip-path: inset(0 -2% 0 0)
  }
}
@media only screen and (max-width: 900px) {
.pac-item-query {
  font-weight: 700 ;
  font-size: 20px ;
}
.pac-item {
  font-size: 20px ;
  height: 40px ;
  display:flex;
  align-items: center;
  justify-content: flex-start;
}
}